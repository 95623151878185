(function () {
  let panels, tablist, tabs;

  window.addEventListener('load', () => {
    tablist = document.querySelector('[role=tablist]');
    tabs = document.querySelectorAll('[role="tab"]');
    panels = document.querySelectorAll('[role="tabpanel"]');

    tabs.forEach(tab => {
      tab.addEventListener('click', onTabClick);
      tab.addEventListener('keydown', onTabKeyDown);
      tab.addEventListener('keyup', onTabKeyUp);
    })
  });

  function activateTab(tab, setFocus) {
    setFocus = setFocus || true;
    deactivateTabs();
    tab.setAttribute('aria-selected', 'true');

    // Get the value of aria-controls (which is an ID)
    const controls = tab.getAttribute('aria-controls');

    // Remove hidden attribute from tab panel to make it visible
    document.getElementById(controls).classList.remove('tabs__panel--hidden');

    // Set focus when required
    if (setFocus) {
      tab.focus();
    }
  }

  function deactivateTabs() {
    tabs.forEach((tab, idx) => {
      tab.setAttribute('tabindex', '-1');
      tab.setAttribute('aria-selected', 'false');
      tab.removeEventListener('focus', onTabFocus);
      tab.index = idx;
    });

    panels.forEach(panel => panel.classList.add('tabs__panel--hidden'));
  }

  // only up and down arrow should function.
  // In all other cases only left and right arrow function.
  function determineOrientation(event) {
    const vertical = tablist.getAttribute('aria-orientation') === 'vertical';
    let proceed = false;

    if (vertical) {
      if (['Up', 'ArrowUp', 'Down', 'ArrowDown'].includes(event.key)) {
        event.preventDefault();
        proceed = true;
      }
    } else {
      if (['Left', 'ArrowLeft', 'Right', 'ArrowRight'].includes(event.key)) {
        proceed = true;
      }
    }

    if (proceed) {
      switchTabOnArrowPress(event);
    }
  }

  function onTabClick(event) {
    activateTab(event.target, false);
  }

  function onTabFocus(event) {
    const target = event.target;

    setTimeout(() => {
      const focused = document.activeElement;

      if (target === focused) {
        activateTab(target, false);
      }
    }, 200);
  }

  function onTabKeyDown(event) {
    switch (event.key) {
      case "End":
        event.preventDefault();
        // Activate last tab
        activateTab(tabs[tabs.length - 1]);
        break;
      case "Home":
        event.preventDefault();
        // Activate first tab
        activateTab(tabs[0]);
        break;

      // Up and down are in keydown
      // because we need to prevent page scroll >:)
      case "Up":
      case "ArrowUp":
      case "Down":
      case "ArrowDown":
        determineOrientation(event);
        break;
    }
  }

  function onTabKeyUp(event) {
    switch (event.key) {
      case "Left":
      case "ArrowLeft":
      case "Right":
      case "ArrowRight":
        determineOrientation(event);
        break;
    }
  }

  function switchTabOnArrowPress(event) {
    const direction = event.key.endsWith('Up') || event.key.endsWith('Left') ? -1 : 1;

    tabs.forEach(tab => tab.addEventListener('focus', onTabFocus));

    const target = event.target;
    if (target.index !== undefined) {
      if (tabs[target.index + direction]) {
        tabs[target.index + direction].focus();
      } else if (direction === -1) {
        tabs[0].focus();
      } else if (direction === 1) {
        tabs[tabs.length - 1].focus();
      }
    }
  }
})();
